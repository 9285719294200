import { Grid, Paper } from "@mui/material";
import React from "react";
import { useAuthentication } from "../../application/authentication";
import { LoginForm } from "./LoginForm";

export const LoginView: React.FC<{}> = () => {
  const { login } = useAuthentication();
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        xl={9}
        sx={{
          backgroundImage: "url(./images/games-background.png)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={4} xl={3} component={Paper} elevation={6} square>
        <LoginForm onSubmit={login} />
      </Grid>
    </Grid>
  );
};
