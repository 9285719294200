import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import TuneIcon from "@mui/icons-material/Tune";
import { AppBar, Badge, Box, IconButton, Toolbar } from "@mui/material";
import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthentication } from "../../../application/authentication";
import { useRefdata } from "../../../application/refdata/useRefdata";
import { UserGameFilter, UserGameFilterData, isFilterActive } from "../../../domain/userGameFilter";
import { ROUTE_DEFAULT } from "../../../lib/constants/routes";
import { Themed } from "../../../lib/types";
import { AddGameDialog } from "../../AddGame/AddGameDialog";
import { ProfileDialog } from "../../Profile/ProfileDialog";
import { RefdataDialog } from "../../Refdata/RefdataDialog";
import { RefdataLoadingStatisticDialog } from "../../Refdata/RefdataLoadingStatisticDialog";
import GamesLogo from "../../assets/games-logo-update.png";
import { FilterDrawer } from "./FilterDrawer";
import { MainMenu } from "./MainMenu";
import { Search } from "./Search";

const Root = styled.div<Themed>`
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  flex-direction: column;
`;

const ContentBlock = styled.div<Themed>`
  display: flex;
  min-height: 100%;
  max-height: 100vh;
  flex-direction: row;
  flex: 1;
`;

const Content = styled.div<Themed>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  scrollbar-width: thin;
`;

const Logo = styled.img<Themed>`
  max-height: 38px;
  display: flex;
`;

const MENU_ID = "account-menu";

type Dialogs = "Refdata" | "Profile" | "LoadingStats" | "AddGame";

type Props = {
  filter?: UserGameFilterData;
  onFilterChange?: (filter: UserGameFilter) => void;
};

export const AppFrame: React.FC<PropsWithChildren<Props>> = ({ children, filter, onFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openedDialog, setOpenedDialog] = useState<Dialogs | undefined>();
  const [openFilter, setOpeneFilter] = useState<boolean>(false);
  const { logout } = useAuthentication();
  const { gameLoadingStats, refdataLoadingStats, loadRefdata, resetLoadingStatistics } = useRefdata();

  const dialog: Dialogs | undefined = useMemo(
    () => (gameLoadingStats.length > 0 || refdataLoadingStats.length > 0 ? "LoadingStats" : openedDialog),
    [openedDialog, gameLoadingStats, refdataLoadingStats]
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (dialog: Dialogs) => () => {
    setOpenedDialog(dialog);
    handleMenuClose();
  };

  const handleRefresh = () => {
    loadRefdata();
    handleMenuClose();
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  const toggleFilter = () => setOpeneFilter(!openFilter);

  const closeDialog = useCallback(() => setOpenedDialog(undefined), [setOpenedDialog]);

  return (
    <Root>
      <ContentBlock>
        <Content className="content-block">{children}</Content>
        {openFilter && filter && onFilterChange && <FilterDrawer data={filter} onChange={onFilterChange} />}
      </ContentBlock>
      {dialog === "AddGame" && <AddGameDialog onClose={closeDialog} />}
      {dialog === "LoadingStats" && <RefdataLoadingStatisticDialog gameStatistics={gameLoadingStats} refdataStatistics={refdataLoadingStats} onClose={resetLoadingStatistics} />}
      {dialog === "Profile" && <ProfileDialog onClose={closeDialog} />}
      {dialog === "Refdata" && <RefdataDialog onClose={closeDialog} />}
      <AppBar position="relative" color="primary" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar style={{ gap: "0.5rem" }}>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <Box sx={{ flexGrow: 1, justifyContent: "space-between", display: "flex" }}>
            <Box sx={{ width: { xs: "30px", sm: "inherit" } }} style={{ overflow: "hidden", boxSizing: "border-box" }}>
              <Link to={ROUTE_DEFAULT}>
                <Logo src={GamesLogo} />
              </Link>
            </Box>
            {filter && onFilterChange && (
              <>
                <Search value={filter?.filter.name} onChange={onFilterChange ? (name) => onFilterChange({ ...filter.filter, name }) : undefined} />
                <IconButton size="large" color="inherit" onClick={toggleFilter}>
                  <Badge badgeContent={""} color="error" invisible={!isFilterActive(filter?.filter)}>
                    <TuneIcon />
                  </Badge>
                </IconButton>
              </>
            )}
            <Box>
              <IconButton size="large" edge="end" aria-label="account of current user" aria-controls={MENU_ID} aria-haspopup="true" onClick={handleMenuOpen} color="inherit">
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
        </Toolbar>
      </AppBar>
      {anchorEl && (
        <MainMenu
          menuId={MENU_ID}
          anchorEl={anchorEl}
          onAddGame={handleSelect("AddGame")}
          onClose={handleMenuClose}
          onLogout={handleLogout}
          onProfile={handleSelect("Profile")}
          onRefdata={handleSelect("Refdata")}
          onRefreshData={handleRefresh}
        />
      )}
    </Root>
  );
};
