import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import moment from "moment";
import { GameStatusHistory, UserGame, UserGameStatus } from "neorak-game-lib-model";
import React from "react";
import { byChangedAt } from "../../../domain/changePlaytime";
import { getName } from "../../../domain/game";
import GameStatusInfo from "../../../lib/constants/gameStatusInfo";
import CoverImage from "../CoverImage";

const HeaderBackground = styled.div<{ src: string }>`
  background-image: ${({ src }) => `url("${src}")`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  filter: brightness(60%) blur(24px);
`;

const Header = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  max-height: 25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    max-height: 50em;
  }
`;

const HeaderDetails = styled.div`
  z-index: 2;
  flex: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  width: 100%;
  /* height: 100%; */
`;

const HeaderImage = styled.div`
  z-index: 2;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;

  @media (max-width: 600px) {
    min-height: 20em;

    > div {
      min-height: 20em;
    }
  }
`;

const HeaderDetailsRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
`;

const HeaderDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

const Title = styled.h1`
  text-align: center;
  margin: 0;
`;

const HeaderTitle = styled.h1`
  text-align: center;
  margin: 0;
  cursor: pointer;
  font-size: 1.5em;
`;

const findLastStatusChange = (status: UserGameStatus, histories: GameStatusHistory[]): GameStatusHistory | undefined => histories.sort(byChangedAt).filter((h) => h.status === status)[0];

type Props = {
  coverUrl?: string;
  game: UserGame;
  onCoverClick: () => void;
  onTitleClick: () => void;
  onDetailsClick: () => void;
};

export const GameHeader: React.FC<Props> = ({ coverUrl, game, onCoverClick, onTitleClick, onDetailsClick }) => {
  if (!coverUrl) return <Title>{game.game.name}</Title>;
  const lastHistory = findLastStatusChange(game.status, game.statusHistory);
  return (
    <>
      {coverUrl && (
        <Header>
          <HeaderBackground src={coverUrl} />
          <HeaderImage>
            <CoverImage alt={game.game.name} src={coverUrl} width="10rem" onClick={onCoverClick} />
          </HeaderImage>
          <HeaderDetails>
            <HeaderTitle onClick={onTitleClick}>{getName(game)}</HeaderTitle>
            <HeaderDetailsRow onClick={onDetailsClick}>
              {game.calcPlaytime && (
                <HeaderDetail>
                  <Typography variant="caption" color="lightgray">
                    Playtime
                  </Typography>
                  <Typography variant="subtitle1">{`${game.calcPlaytime || 0} hrs`}</Typography>
                </HeaderDetail>
              )}
              {game.status !== UserGameStatus.NONE && (
                <HeaderDetail>
                  <Typography variant="caption" color="lightgray">
                    {GameStatusInfo[game.status].label}
                  </Typography>
                  <Typography variant="subtitle1">{lastHistory?.statusChangedAt ? moment(lastHistory.statusChangedAt).format("L") : "sometime"}</Typography>
                </HeaderDetail>
              )}
            </HeaderDetailsRow>
          </HeaderDetails>
        </Header>
      )}
    </>
  );
};
