import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DataloadingStatistic } from "../../../domain/dataLoadingStatistic";
import { sortRefdataByName } from "../../../lib/sort";
import { AppRootState } from "./store";

const updateStatistic = (action: PayloadAction<DataloadingStatistic>, statistics: DataloadingStatistic[]) =>
  statistics.map((st) => {
    if (st.id === action.payload.id) {
      return action.payload;
    }
    return st;
  });

export type DataLoadingStatisticState = {
  refdata: DataloadingStatistic[];
  game: DataloadingStatistic[];
};

const initialState: DataLoadingStatisticState = {
  refdata: [],
  game: [],
};

const DataLoadingStatisticSlice = createSlice({
  name: "dataLoadingStatistic",
  initialState,
  reducers: {
    addGameLoadingStatistics: (state: DataLoadingStatisticState, action: PayloadAction<DataloadingStatistic>) => {
      state.game = [...(state.game || []), action.payload];
    },
    addRefdataLoadingStatistics: (state: DataLoadingStatisticState, action: PayloadAction<DataloadingStatistic>) => {
      state.refdata = [...(state.refdata || []), action.payload];
      state.refdata.sort(sortRefdataByName);
    },
    updateGameLoadingStatistic: (state: DataLoadingStatisticState, action: PayloadAction<DataloadingStatistic>) => {
      state.game = updateStatistic(action, state.game);
    },
    updateRefdataLoadingStatistic: (state: DataLoadingStatisticState, action: PayloadAction<DataloadingStatistic>) => {
      state.refdata = updateStatistic(action, state.refdata);
    },
    reset: () => {
      return { ...initialState };
    },
  },
});

// exports
export const dataLoadingStatisticSelector = (state: AppRootState): DataLoadingStatisticState => state.dataLoadingStatistic;

export const { reset, addGameLoadingStatistics, addRefdataLoadingStatistics, updateGameLoadingStatistic, updateRefdataLoadingStatistic } = DataLoadingStatisticSlice.actions;

export default DataLoadingStatisticSlice.reducer;
